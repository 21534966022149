<template>
<el-card shadow="never" :body-style="{ padding: '20px' }" class="mb-4 mx-auto">
	<div class=" mx-auto p-1 text-gray-600">
		<h2 class="text-2xl mb-4">FAQ</h2>
		<div class="mb-4">
			<h3 class="font-bold mb-1">What is Anyloader?</h3>
			<p>
				The Anyloader is a software designed to allow users to download content from websites like OnlyFans, from posts, profiles and chats for offline viewing.
			</p>
		</div>
		
		<div class="mb-4">
			<h3 class="font-bold mb-1">
				How does the app work?
			</h3>
			<p>
				The app requires a chrome extension to be installed and communicates with the chrome extension to find and download the media content associated with the given link.
			</p>
		</div>		
		<div>
			<h3 class="font-bold mb-1">Can I download media from paid subscription based onlyfans accounts? </h3>
			<p>
				Yes, you can if you are subscribed to the account. 
			</p>
		</div>		
	</div>
</el-card>
</template>

<script>
export default {

}
</script>

<style>

</style>