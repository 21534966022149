<template>
<div>
    <h1 class="mb-4 text-gray-500">Onlyfans Downloader</h1>
    <client-only>
        <template #fallback>
            <el-card shadow="never" :body-style="{padding: '0'}" class="mb-4">
                <div style="height: 100px" class="flex items-center justify-center">
                    <ElIconLoading :width="20" class="mr-2"/> 
                    Loading the app
                </div>
            </el-card>
        </template>
        <downloader></downloader>
    </client-only>
    <el-card shadow="never" class="mb-4" :body-style="{ padding: '20px' }">
        <h2 class="text-2xl mb-4">
            Demo video
        </h2>
        <div class="mb-4"> Learn how to get started with onlyfans downloader by watching the demo video </div>
        <el-button type="info" size="large" :icon="ElIconVideoPlay" @click="showDemoVideo = true" v-if="!showDemoVideo">Watch video</el-button>
        
        <video controls autoplay="false" v-show="showDemoVideo">
            <source src="https://anyloader.com/onlyfans_downloader_demo.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </el-card>
	<onlyfans-faq></onlyfans-faq>
</div>
</template>
<script>
export default {
    created() {
        useHead({
            link: [
                {rel: 'canonical', href: useRuntimeConfig().public.siteUrl}
            ]
        })
    },
	data () {
        return {
            showDemoVideo: false
        }
    }
}
</script>